import React from 'react';
import DOMPurify from 'isomorphic-dompurify';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
  root: {
    '& h1, h2, h3, h4, h5, h6, p': {
      marginTop: 0,
      marginBottom: 0
    },
    /* This hack is needed to override font styles which attached directly to `span` tag which comes from backend (added in dashboard via TinyMCE)  */
    '@global': {
      span: {
        fontFamily: 'Open Sans !important'
      }
    }
  }
});

export const RichTextContent = ({htmlContent, className}) => {
  const classes = useStyles();

  return (
    <>
      {htmlContent && (
        <div
          className={clsx(classes.root, className)}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(htmlContent)
          }}
        />
      )}
    </>
  );
};
